import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CTAButtonWithText } from '../CTAButtonWithText';
import { StyledCallToAction } from '../../../sharedComponents';
import { DARK, LIGHT } from '../../constants';
import { ThemeContext } from 'styled-components';

export const CTAButtonsList = ({ list, style }) => {
  const themeConfig = useContext(ThemeContext);

  const ctaMode = () => {
    if (style === LIGHT) return 'black';
    return 'none';
  };

  return list
    .filter((button) => button.link.shown)
    .map((button, index) => {
      const { buttonStyle, textPosition, textNextToCTAButton, link } = button;

      const visualButtonStyles =
        themeConfig.siteBuilderModules.ctaButtonGroupModule.visualStyles[
          buttonStyle
        ];

      return (
        <CTAButtonWithText
          key={index}
          textPosition={textPosition}
          text={textNextToCTAButton}
          visualButtonStyles={visualButtonStyles}
        >
          <StyledCallToAction
            mode={ctaMode()}
            ctaConfig={link}
            size="medium"
            look="accent"
            visualStyles={visualButtonStyles}
          />
        </CTAButtonWithText>
      );
    });
};

CTAButtonsList.propTypes = {
  list: PropTypes.array,
  style: PropTypes.oneOf([LIGHT, DARK]).isRequired,
};
