import React from 'react';
import PropTypes from 'prop-types';
import {
  CTAButtonCell,
  CTAButtonContainerStyled,
  CTAButtonMiddleBlock,
  CTAButtonTopBottomBlock,
} from '../../styles';

const TEXT_POSITIONS = {
  ABOVE: 'ABOVE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  BELOW: 'BELOW',
};

export const CTAButtonWithText = ({
  children,
  textPosition,
  text,
  visualButtonStyles,
}) => {
  const isTextAbove = textPosition === TEXT_POSITIONS.ABOVE;
  const isTextOnLeft = textPosition === TEXT_POSITIONS.LEFT;
  const isTextOnRight = textPosition === TEXT_POSITIONS.RIGHT;
  const isTextBelow = textPosition === TEXT_POSITIONS.BELOW;

  return (
    <CTAButtonContainerStyled styles={visualButtonStyles}>
      <CTAButtonTopBottomBlock>{isTextAbove && text}</CTAButtonTopBottomBlock>

      <CTAButtonMiddleBlock>
        {isTextOnLeft && <CTAButtonCell>{text}</CTAButtonCell>}
        <CTAButtonCell>{children}</CTAButtonCell>
        {isTextOnRight && <CTAButtonCell>{text}</CTAButtonCell>}
      </CTAButtonMiddleBlock>

      <CTAButtonTopBottomBlock>{isTextBelow && text}</CTAButtonTopBottomBlock>
    </CTAButtonContainerStyled>
  );
};

CTAButtonWithText.propTypes = {
  children: PropTypes.node.isRequired,
  textPosition: PropTypes.string.isRequired,
  text: PropTypes.string,
  visualButtonStyles: PropTypes.object.isRequired,
};
