import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CTAButtonContainerStyled = styled('div')`
  display: inline-block;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: 12px;
    @media (max-width: 767px) {
      margin-right: 7px;
    }
  }

  a {
    ${({ theme, styles }) =>
      renderStyledElementStyles(theme, styles.elements?.callToActionButton)}
  }

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.textNextToCallToAction,
    )};
`;
