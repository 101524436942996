import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import {
  SiteBuilderModule,
  CallToActionConfigProps,
  StyledHeading,
} from '../sharedComponents';

import { renderStyledElementStyles } from '../../helpers';
import { LIGHT, DARK } from './constants';
import {
  CTAButtonGroupHeaderWrapper,
  CTAButtonGroupListWrapper,
} from './styles';
import { CTAButtonsList } from './innerComponents/CTAButtonsList';
import { renderHTMLString } from '../../../../helpers';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.ctaButtonGroupModule,
    )}

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
`;

export const IntroText = styled.div`
  padding-top: 12px;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.paragraph)};
`;

const CTAButtonGroupModule = (props) => {
  const {
    section: { title, titleSize, style, subsections, introText },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const vStyles =
    themeConfig.siteBuilderModules.ctaButtonGroupModule.visualStyles[style];

  const buttonsList = subsections.filter((subsection) => subsection.link?.type);

  return (
    <SiteBuilderModule section={props.section} moduleTheme={{ vStyles }}>
      <Wrapper>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <CTAButtonGroupHeaderWrapper>
                <StyledHeading
                  content={title}
                  as={titleSize}
                  visualStyles={vStyles}
                />
                {introText && (
                  <IntroText>{renderHTMLString(introText)}</IntroText>
                )}
              </CTAButtonGroupHeaderWrapper>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xs-12 col-md-12"
              data-testid="cta-button-group-cta"
            >
              {buttonsList.length > 0 && (
                <CTAButtonGroupListWrapper>
                  <CTAButtonsList list={buttonsList} style={style} />
                </CTAButtonGroupListWrapper>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </SiteBuilderModule>
  );
};

CTAButtonGroupModule.propTypes = {
  section: PropTypes.shape({
    style: PropTypes.oneOf([LIGHT, DARK]).isRequired,
    title: PropTypes.string,
    titleSize: PropTypes.string,
    text: PropTypes.string,
    link: CallToActionConfigProps,
    subsections: PropTypes.array,
  }).isRequired,
};

CTAButtonGroupModule.defaultProps = {
  section: {
    title: '',
    link: {},
    style: '',
    subsections: [],
  },
};

export default CTAButtonGroupModule;
